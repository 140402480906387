import { init, FullStory } from '@fullstory/browser';
import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';
import addScript from 'src/utils/addScript';
import getFeatureFlagValue from 'src/utils/getFeatureFlagValue';
import getTenantId from 'src/utils/getTenantId';

const FULLSTORY_SCRIPT_SRC = 'edge.fullstory.com/s/fs.js';
const FULLSTORY_HOST = 'fullstory.com';
const FULLSTORY_ORG = 'o-21C08X-na1';
const FULLSTORY_NAMESPACE = 'FS';

declare global {
  interface Window {
    _O: {
      ifOptInGroups: (group: string) => boolean;
    };
  }
}

export const loadFullStoryScript = async (
  logger: Record<EnumAllowedLogType, LogFuncType>
) => {
  const ff = await getFeatureFlagValue(logger, 'fullstory');
  if (!ff) {
    logger.log('feature flag is false, script not loaded');
    return;
  }

  init({
    orgId: FULLSTORY_ORG,
    host: FULLSTORY_HOST,
    namespace: FULLSTORY_NAMESPACE,
    script: FULLSTORY_SCRIPT_SRC
  });

  addScript(
    `https://${FULLSTORY_SCRIPT_SRC}`,
    logger,
    () => {
      if (FullStory) {
        logger.log('is tracking user interactions');
        // Associate users in Fullstory with unique personal org ID
        const tenantId = getTenantId(logger);
        if (tenantId) {
          try {
            FullStory('setIdentity', {
              uid: tenantId
            });
            logger.log('user associated');
          } catch (error) {
            logger.error('error associating user', error);
          }
        }
      } else {
        logger.log('is not tracking user interactions');
      }
    },
    'fullstory-script',
    'text/javascript'
  );
};

/**
 * Waits for the window._O object to be available and then executes the callback function.
 * Logs an error if the object is not available within the specified timeout.
 *
 * @param {Record<EnumAllowedLogType, LogFuncType>} logger - The logger instance to log messages.
 * @param {() => void} callback - The callback function to execute once the window._O object is available.
 * @param {number} [interval=100] - The interval in milliseconds to check for the window._O object.
 * @param {number} [timeout=5000] - The timeout in milliseconds to wait for the window._O object.
 */
const waitForOObject = (
  logger: Record<EnumAllowedLogType, LogFuncType>,
  callback: () => void,
  interval = 100,
  timeout = 5000
) => {
  const startTime = Date.now();

  const checkAvailability = () => {
    if (window._O) {
      callback();
    } else if (Date.now() - startTime < timeout) {
      setTimeout(checkAvailability, interval);
    } else {
      logger.error(`window._O object not found after waiting for ${timeout}ms`);
    }
  };

  checkAvailability();
};

/**
 * Background Task: bgtInjectFullstorySnippet
 *
 * This function asynchronously injects the Fullstory snippet into the My Account Portal using ClientOS background tasks.
 *
 *  * Process Details:
 * 1. Wait for the OObject to be ready. If the OObject is not available after 5 seconds, log an error.
 * 2. Check if the user has accepted the analytics agreement. Opt-in group ('2:1')
 * 3. Check FullStory feature flag value.
 * 4. Load the FullStory script if the user is in the specified opt-in group and the feature flag is true.
 * 5. Log errors and throw exceptions on failure.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const bgtInjectFullstorySnippet = async (): Promise<void> => {
  const shell = window?.Shell?.v1;
  if (!shell) {
    console.error('bgtInjectFullstorySnippet: Shell is not defined');
    return;
  }

  const logger = shell.logger.createLoggerInstance({
    preffixLog: 'Fullstory:'
  });

  waitForOObject(logger, async () => {
    if (window._O.ifOptInGroups('2:1')) {
      logger.log('user is in opt-in group');
      await loadFullStoryScript(logger);
    } else {
      logger.log('user is not in opt-in group, script not loaded');
    }
  });
};
