import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';

const getTenantId = (
  logger: Record<EnumAllowedLogType, LogFuncType>
): string | null => {
  try {
    return window?.Shell?.v1?.tenantHandlerInterface?.getTenantId() ?? null;
  } catch (error) {
    logger.error('error retrieving tenantId', error);
    return null;
  }
};

export default getTenantId;
